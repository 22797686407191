module.exports = {
  title_info:
    "You can also print out this form and send us via fax: (62-21) 251 1777",
  label_confirmation_no: "Confirmation No.",
  label_guest_name: "Guest Name",
  label_list_hotel: "Hotel",
  placeholder_hotel_selected: "Select hotel",
  label_check_in_date: "Check in Date",
  label_check_out_date: "Check Out Date",
  label_file_upload: "Upload attachment",
  label_comment: "Comment",
  btn_label: "SEND",
  submit_success: "Your missing point inquiry has been successfully submitted.",
  submit_error:
    "We encountered an error in retrieving your data. Please try again later, or contact our team at info@ayanarewards.com for assistance.",
};
