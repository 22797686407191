module.exports = {

	'label_edit_profile': 'Edit Profile',
	'label_id_card': 'ID Card',
	'label_no_password': 'Passport No.',
	'label_title': 'Title',
	'label_gender': 'Gender',
	'label_marital_status': 'Marital Status',
	'label_email_address': 'Email Address',
	'label_nationality': 'Nationality',
	'label_birthday': 'Birthday',
	'label_language': 'Language',
	'label_member_referal': 'Member Referal',
	'label_company': 'Company',
	'label_mobile_phone': 'Mobile Phone',
	'label_home_phone': 'Home Phone',
	'label_office_phone': 'Office Phone',
	'label_fax_number': 'Fax Number',
	'label_address': 'Address',
	'label_state': 'State',
	'label_postal_code': 'Postal Code',
	'label_city': 'Country of Residence',
	'label_join_date': 'Join Date',
	'label_expiry_date': 'Expiry Date',
	'label_edit_information': 'Edit My Information'
}