module.exports = {

	'title_welcome': 'Welcome, ',
	'information': 'We want to make your AYANA experience special. As our member, you\'ll enjoy free nights, upgrades, members-only offers and a welcome amenity every time you stay with us, wherever that may be.',
	'member_name_label': 'Member Name',
	'membership_no': 'Membership no.',
	'label_membership': 'Membership ',
	'membership_gold_label': 'Gold',
	'membership_silver_label': 'Silver',
	'membership_platinum_label': 'Platinum',
	'point_label': 'YOUR POINTS',
	'menu_tab_profile': 'Profile Page',
	'menu_tab_my_redeem': 'My Redeem',
	'menu_tab_password_change': 'Password Change',
	'menu_tab_missing_point': 'Missing Point',
	'menu_tab_reward_history': 'Reward History',
	'menu_tab_future_booking': 'Future Booking',
	'menu_tab_feedback': 'Feedback',
	'menu_tab_my_voucher': 'My Vouchers',
	'menu_tab_transaction_history': 'Points History',
	'menu_tab_my_reservation': 'My Reservation',
	'label_your_point': 'Your points',
	'label_expired_on': 'Expired on',
	'label_required_nex_point': 'pts to go'
}