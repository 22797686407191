module.exports = {
    'button_upcoming': 'Upcoming',
    'button_history': 'History',
    'title_confirmation_no': 'Confirmation No',
    'title_booking_date': 'Booking Date',
    'title_arrival': 'Arrival',
    'title_departure': 'Departure',
    'title_hotel_name': 'Hotel Name',
    'title_status': 'Status',
    'reservation_footnote': 'It may take up to two (2) days for your reservation to be updated here.',
}