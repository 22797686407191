module.exports = {
    'title_processed_date': 'Processed Date',
    'title_description': 'Description',
    'title_place_of_visit': 'Place of Visit',
    'title_transaction_reference': 'Transaction Ref',
    'title_transaction_type': 'Transaction Type',
    'title_earned_point': 'Earned Points',
    'title_bonus_point': 'Bonus Points',
    'title_total_point': 'Total Points',
    'title_total_expiry': 'Points Expiry'
}