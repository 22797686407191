module.exports = {
  placeholder_email: "Your email",
  placeholder_subject: "Subject",
  placeholder_hotel: "Hotel",
  placeholder_selected_hotel: "Select hotel",
  placeholder_message: "Message",
  btn_label: "Send my feedback",
  submit_success: "Your feedback has been successfully submitted.",
  submit_error:
    "We encountered an error in retrieving your data. Please try again later, or contact our team at info@ayanarewards.com for assistance.",
};
